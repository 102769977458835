export default function Configs() {
  const tableColumns = [
    {
      label: "采集通道名称",
      prop: "sensorName",
      align: "center",
      show: true
    },
    {
      label: "在线状态",
      prop: "status",
      align: "center",
      show: true
    },
    {
      label: "数量",
      prop: "pointSize",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    tableColumns
  };
}
