<template>
  <div class="single-bridge-common">
    <div class="tit-search flex space-between">
      <div class="tit-con" :style="'width:' + 100 / list.length + '%'" v-for="(item,i) in list" :key="i">
        <strong>{{item.value}}<span>{{item.unit}}</span></strong>
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="table-wrapper">
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-update" style="color:#F4BB4E" @click="gotoDetail(row)">查看传感器列表</span>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
  import Configs from "./config/index";
  export default {
    data() {
      let { tableColumns } = Configs(this);
      return {
        tableColumns,
        dialogVisible: false,
        title:'',
        pageData:[],
        form:{
          model:{},
          rules:{}
        },
        sensorList:[],
        list:[
          {name:'采集通道在线数量',value:'100',unit:'个'},
          {name:'采集通道离线数量',value:'100',unit:'个'},
          {name:'采集通道在线率',value:'100',unit:'%'},
          {name:'采集通道总数',value:'100',unit:'个'},
        ]
      };

    },
    created() {
      this.pageGet()
    },
    methods: {
      pageGet(){
        this.$http.post('/equipment/situation/status/list').then(res => {
          if (res.success){
            this.pageData = res.data.list
            this.list[0].value = res.data.onlineSize
            this.list[1].value = res.data.offlineSize
            this.list[2].value = res.data.onlineRate
            this.list[3].value = res.data.pointSize
          }
        })
      },
      gotoDetail(data){
        this.$router.push({path:'/single/situation/sensor/detail',query:{sensorTypeId:data.sensorTypeId}})
      }
    },
  };
</script>

<style scoped>
  .single-bridge-common .table-wrapper{
    height: calc(100% - 200px);
  }
  .c-table{
    height: auto;
    min-height: 100%;
    overflow-y: auto;
  }
  .c-table::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
  }
  .c-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  .c-table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #999;
  }
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
  .opt-update:hover{
    cursor: pointer;
  }
  .tit-con{
    height: 180px;
    margin: 0 10px 20px;
    border-radius: 8px;
    background: #ebba0c;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 20px;
  }
  .tit-con:first-child{
    margin-left: 0;
  }
  .tit-con:nth-child(2){
    background: #d30404;
  }
  .tit-con:nth-child(3){
    background: #eb7a10;
  }
  .tit-con:last-child{
    margin-right: 0;
    background: #07b983;
  }
  .tit-con strong{
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 58px;
    font-family: "Quartz";
  }
  .tit-con strong span{
    font-size: 22px;
    margin-left: 5px;
  }
  .tit-con>span{
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }
</style>